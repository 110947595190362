import React from "react";
import { Button, Space, Table, Tag, Input, Spin, Tab  } from 'antd';
import { LoadingOutlined, SearchOutlined} from '@ant-design/icons';
import { useState } from "react";
import { useEffect } from "react";

function PropertyIssues() {

    //setea lista en la tabla
const [dataSource, setDataSource] = useState([
    {
    id: "test",
    Name: "test",
    LOIStatus: "test",
    entity: "test,"
    },
    {
    id: "tast",
    Name: "tast",
    LOIStatus: "tast",
    entity: "tast,"
    },
    {
        id: "tist",
        Name: "tist",
        LOIStatus: "tost",
        entity: "tist,"
        },

])

const [loading, setLoading] = useState(true);
const [testingg, setTesttingg] = useState(true);


//make a reques on the table
useEffect(() => {
    setLoading(true);
   
    fetch('https://app.asana.com/api/1.0/projects/1206313814206744/tasks?opt_fields=name,assignee,completed,custom_fields,gid,parent,due_on ',
    {
        method: 'GET',
        headers: {
            'Authorization': `Bearer 2/1206524890494959/1206900667183984:cb7dbca327758d58acea87a8a0d3590d`
        }
    }
)
    .then(response => {
      if (!response.ok) {
        throw new Error('Error en la solicitud');
      }
      return response.json();
    })
    .then(data => {
      // Aquí puedes manipular los datos recibidos en formato JSON
      setDataSource([]);
      console.log(data);
      const acaseras = data.data;
      console.log(data.data[0]);

      
                   
     const newMap = acaseras.map(DataHere => {
        let suiteDate = DataHere.custom_fields[0].text_value === null ? "---" : DataHere.custom_fields[0].text_value;
        let entityActive = DataHere.custom_fields[1].display_value === null ? "---" : DataHere.custom_fields[1].display_value;
        let staeCompleted = DataHere.completed === true ? "Completed" : "No Completed";
        let DueDataText = DataHere.due_on != null ? DataHere.due_on.toString() : "---";
           
            try {
              
                 
        setDataSource(prev=>{
            //const urlAncla = `href="https://app.asana.com/0/1202771100962765/${DataHere.gid}/f"` ;
            return [...prev, {
                key:DataHere.gid, 
                Id: DataHere.gid, 
                PropertyEntity: entityActive, 
                Task: DataHere.name, 
                Assignee: DataHere.custom_fields[1].created_by.name,
                dueon: DueDataText,
                suite: suiteDate,
                completed: staeCompleted    } ]} )
            
               
            } catch (error) {
                console.log(error)
            }
            
          });

setLoading(false);
})
    .catch(error => {
      console.error('Hubo un problema con la solicitud:', error);
    });
  
    console.log("hola por aca")

}, [testingg]);

const columns = [
    
    {
        title: 'Property Entity',
        dataIndex: 'PropertyEntity',
        key: 'Property Entity',
        
    },
    {
        title: 'Task',
        dataIndex: 'Task',
        key: 'Task',
        
        
    
        
    },
    {
        title: 'Assignee',
        dataIndex: 'Assignee',
        width: 200,
        
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            
            return (
            <>
              <Input 
              autoFocus 
              placeholder="Type text here"
              value={selectedKeys[0]}
              onChange={(e)=> {
                  
                  setSelectedKeys(e.target.value ? [e.target.value]:[])
              }}
              onPressEnter={()=> {
                  confirm()
              }}
              onBlur={()=> {
                  confirm()
              }}
              >
              </Input>
              <Button 
              type="primary"
              onClick={() => {
                  confirm();
              }} 
              >Search
              </Button>
              <Button onClick={() => {
                try {
                    clearFilters();
                    confirm();
                } catch (error) {
                    
                }
                 
              }} 
              danger>
                  Reset
              </Button>
            </>
            );
        },
        filterIcon:() => {
            return <SearchOutlined/>
        },
        onFilter: (value, record) => {
            try {
                return record.Assignee.toLowerCase().includes(value.toLowerCase())
            } catch (error) {
                
            }
           
            
        },        
    },
   
    {
        title: 'Due on',
        dataIndex: 'dueon',
        key: 'dueon',
        width: 200,
    },
    {
        title: 'Suite',
        dataIndex: 'suite',
        key: 'suite',
        width: 100,
        
},
{
    title: 'Is Completed',
    dataIndex: 'completed',
    key: 'completed',
    width: 150,
   filters: [
        {
            text:"Completed",
            value:"Completed",
        },
        {
            text:"No Completed",
            value:"No Completed",
        },
        
    ],
    onFilter: (value, record) => {
        try {
           return record.completed.indexOf(value) === 0
        } catch (error) { 
    }
    
}},

{
    title: 'Link',
    dataIndex: 'Id',
    key: 'Id',
    width: 100,
    render: (text) => <a href={'https://app.asana.com/0/1202771100962765/' + text + '/f'} target="_blank">View task</a>,
},

]








const onChange = (pagination, filters) => {
    console.log('params', pagination, filters);
  };
    

    return (
        <>
        <h1>Property Issues</h1>
        <Spin 
        tip="Loading..." 
        spinning={loading} 
        size="large"
        className="splinLoad"
        
        >
        {loading ? '' : <Table   columns={columns} dataSource={dataSource} onChange={onChange} /> }
        </Spin>
       
        </>
    )
    
}

export { PropertyIssues }
