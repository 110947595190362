import React from "react";
import { Tabs  } from 'antd';
import { useState } from "react";
import { PropertyIssues } from './PropertyIssues ';
import { WorkingTenantIssues } from "./WorkingTenantIssues";
import { TenantConstructionStatus } from "./TenantConstructionStatus";
import { PlusOutlined } from '@ant-design/icons';


function Properties(e) {
    const [propertiesActive, setPropertiesActive ] = useState (true);
    const [WorkingTenant, setWorkingTenat ] = useState (false);
    const [tenantConstruction, setTenantConstruction ] = useState (false);
   
    const onChange = (key) => {

        console.log(key);

        if (key === '1') {
            setPropertiesActive(true);
            setWorkingTenat(false);
            setTenantConstruction(false)
          }else if (key === '2'){
            setWorkingTenat(true);
            setPropertiesActive(false);
            setTenantConstruction(false)
          } else if (key === '3') {
            setTenantConstruction(true)
            setWorkingTenat(false);
            setPropertiesActive(false);
          }
           else {
            
          }

      };
      const items = [
        {
          key: '1',
          label: 'Property Issues',
          icon:<PlusOutlined />,
          
        },
        {
          key: '2',
          label: 'WorkingTenant Issues',
          icon:<PlusOutlined />,
          
        },
        {
          key: '3',
          label: 'Tenant Construction Status',
          icon:<PlusOutlined />,
          
        },
      ];

    return (
        <>
       <Tabs 
       size= 'large' 
       defaultActiveKey="1" 
       items={items} 
       type="card"
       onChange={onChange} />
      
       {propertiesActive && <PropertyIssues />}
       
       {WorkingTenant && <WorkingTenantIssues />}
       
       {tenantConstruction && <TenantConstructionStatus />}
        </>
    )
    
}

export { Properties }